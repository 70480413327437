import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useHookstate } from '@hookstate/core';
import Layout from '../Layout';
import ClassService from '../../services/class';
import { Criteria, Metric } from '../../types';
import a from '../../services/config';
import { globalNotifications } from '../../state';

const EditEvaluationPage: React.FC = () => {
  const notification = useHookstate(globalNotifications);
  const history = useHistory();
  const [id, evaluatorType] = window.location.href
    .split('/edit/')[1]
    .split('/');

  const [allCriteria, setAllCriteria] = useState([]);
  const [allMetrics, setAllMetrics] = useState<
    Array<{ criteria: Criteria; rating: number; comments?: string }>
  >([]);
  const [error, setError] = useState<string | null>(null);
  const [alert, setAlert] = useState({ show: false, message: '', type: '' });

  const fetchEvaluation = async () => {
    try {
      const { data } = await a.get(`evaluations/edit/${id}`);
      setAllMetrics(data.metrics);
      // Assuming the API returns the evaluator type as well
      fetchSurvey(evaluatorType);
    } catch (error) {
      setError('Failed to fetch evaluation data. Please try again later.');
      console.error('Error fetching evaluation:', error);
    }
  };

  const fetchSurvey = async (evaluatorType: string) => {
    try {
      const { data } = await a.get(`/evaluations/${evaluatorType}`);
      setAllCriteria(data);
    } catch (error) {
      setError('Failed to fetch survey data. Please try again later.');
      console.error('Error fetching survey:', error);
    }
  };

  useEffect(() => {
    fetchEvaluation();
  }, [id]);

  const handleRatingChange = (criteria: Criteria, rating: number) => {
    setAllMetrics((prev) => {
      const existingMetricIndex = prev.findIndex(
        (metric) => metric.criteria === criteria,
      );
      if (existingMetricIndex !== -1) {
        const newMetrics = [...prev];
        newMetrics[existingMetricIndex] = {
          ...newMetrics[existingMetricIndex],
          rating,
        };
        return newMetrics;
      }
      return [...prev, { criteria, rating, comments: '' }];
    });
  };

  const handleCommentChange = (criteria: Criteria, comments: string) => {
    setAllMetrics((prev) => {
      const existingMetricIndex = prev.findIndex(
        (metric) => metric.criteria === criteria,
      );
      if (existingMetricIndex !== -1) {
        const newMetrics = [...prev];
        newMetrics[existingMetricIndex] = {
          ...newMetrics[existingMetricIndex],
          comments,
        };
        return newMetrics;
      }
      return [...prev, { criteria, rating: 0, comments }];
    });
  };

  const handleSubmit = async () => {
    if (allMetrics.length !== allCriteria.length) {
      setError('Please select a rating for all criteria before submitting.');
      return;
    }
    try {
      await a.put(`evaluations/edit/${id}`, { metrics: allMetrics });
      setError(null);
      history.goBack();
    } catch (error) {
      setError('Failed to update evaluation. Please try again.');
      console.error('Error updating evaluation:', error);
    }
  };

  useEffect(() => {
    if (error) {
      setAlert({
        show: true,
        message: error,
        type: 'error',
      });
    }
  }, [error]);

  return (
    <Layout bg>
      <div className='container mx-auto px-4 py-8 max-w-4xl'>
        <div className='bg-white rounded-lg shadow-lg p-8'>
          {alert.show && (
            <div
              className={`mb-4 p-4 rounded ${
                alert.type === 'error'
                  ? 'bg-red-100 text-red-700'
                  : 'bg-green-100 text-green-700'
              }`}
            >
              {alert.message}
            </div>
          )}
          <h1 className='text-3xl font-bold mb-6 text-center text-gray-800'>
            Edit Evaluation
          </h1>
          {allCriteria.map((criteriaObj) => (
            <div
              key={criteriaObj.criteria}
              className='mb-8 p-6 rounded-lg shadow-md bg-gray-100'
            >
              <h2 className='text-xl font-semibold mb-4 text-gray-800'>
                {criteriaObj.criteria}
              </h2>
              <div className='mb-4 grid grid-cols-5 gap-4'>
                {criteriaObj.rating.map((ratingText, index) => (
                  <div key={index} className='text-center'>
                    <button
                      className={`w-full py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                        allMetrics.find(
                          (m) => m.criteria === criteriaObj.criteria,
                        )?.rating === index
                          ? 'bg-blue-500 text-white'
                          : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                      }`}
                      onClick={() =>
                        handleRatingChange(criteriaObj.criteria, index)
                      }
                    >
                      {index + 1}
                    </button>
                    <p className='mt-2 text-sm text-gray-600'>{ratingText}</p>
                  </div>
                ))}
              </div>
              <textarea
                className='w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white'
                value={
                  allMetrics.find((m) => m.criteria === criteriaObj.criteria)
                    ?.comments || ''
                }
                onChange={(e) =>
                  handleCommentChange(criteriaObj.criteria, e.target.value)
                }
                placeholder='Additional comments'
              />
            </div>
          ))}
          <div className='text-center'>
            <button
              className='bg-blue-500 text-white px-6 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50'
              onClick={() => handleSubmit()}
            >
              Update Evaluation
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default EditEvaluationPage;
