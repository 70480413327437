export const ALL_CLASSES = 'All Classes';
export const ENDED = 'Ended';
export const IN_SESSION = 'In Session';

export const COHORT_STATUS = {
  ALL_CLASSES: 'All Classes',
  ALL_MY_CLASSES: 'All My Classes',
  ENDED: 'Ended',
  IN_SESSION: 'In Session',
  UPCOMING: 'Upcoming',
};

export const ROLES = {
  STUDENT: 'student',
  TEACHER: 'teacher',
};

export const UPCOMING = 'Upcoming';
export const blue = 'rgb(37, 99, 235)';
export const green = '#10B981';
export const yellow = '#F59E0B';
export const red = '#EF4444';

export const ssoServer = 'https://sso.thefuturesphere.com';
export const ssoInvitationFlow = '/if/flow/invitation-enrollment/';

export const GUEST_STUDENT_ID = '00258634';

export const SUPPORTED_LANGUAGES = ['Python', 'Java'];
