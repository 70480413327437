import Layout from '../Layout';
import React, { useState, useEffect } from 'react';
import a from '../../services/config';
import { Pagination } from '@bctc/components';
import dayjs from 'dayjs';
import Empty from '../../components/Empty';
import { Link } from 'react-router-dom';
import { useHookstate } from '@hookstate/core';
import { globalRole } from '../../state';
import { Persistence } from '@hookstate/persistence';

const EvaluationEditViewPage: React.FC = () => {
  const userId = window.location.href.split('/evaluation/')[1];
  const role = useHookstate(globalRole);
  role.attach(Persistence('state.role'));
  const isTeacher = role.value === 'teacher';
  const userType = role.value === 'teacher' ? 'teacher' : 'student';
  const oppositeUserType = role.value === 'teacher' ? 'student' : 'teacher';


  // Created Evaluations
  const [createdEvaluations, setCreatedEvaluations] = useState({
    data: [],
    total: 0,
    totalPages: 0,
  });
  const [createdEvaluationsPage, setCreatedEvaluationsPage] = useState(1);
  const [createdSearchTerm, setCreatedSearchTerm] = useState('');

  // Received Evaluations
  const [receivedEvaluations, setReceivedEvaluations] = useState({
    data: [],
    total: 0,
    totalPages: 0,
  });
  const [receivedEvaluationsPage, setReceivedEvaluationsPage] = useState(1);
  const [receivedSearchTerm, setReceivedSearchTerm] = useState('');

  const EVALUATION_LIMIT = 10;

  const fetchCreatedEvaluations = async () => {
    try {
      const { data } = await a.get(
        `/evaluations/${userType}/${userId}/created`,
        {
          params: {
            page: createdEvaluationsPage,
            pageSize: EVALUATION_LIMIT,
            searchQuery: createdSearchTerm,
          },
        },
      );
      setCreatedEvaluations(data);
    } catch (error) {
      console.error('Error fetching created evaluations:', error);
    }
  };

  const fetchReceivedEvaluations = async () => {
    try {
      const { data } = await a.get(
        `/evaluations/${userType}/${userId}/received`,
        {
          params: {
            page: receivedEvaluationsPage,
            pageSize: EVALUATION_LIMIT,
            searchQuery: receivedSearchTerm,
          },
        },
      );
      setReceivedEvaluations(data);
    } catch (error) {
      console.error('Error fetching received evaluations:', error);
    }
  };

  useEffect(() => {
    fetchCreatedEvaluations();
  }, [createdEvaluationsPage, createdSearchTerm]);

  useEffect(() => {
    fetchReceivedEvaluations();
  }, [receivedEvaluationsPage, receivedSearchTerm]);

  return (
    <Layout bg>
      <div className='max-w-7xl m-auto px-8'>
        <div className='bg-white rounded-lg px-4 sm:px-6 lg:px-8 py-6'>
          <h2 className='text-base font-semibold leading-6 text-gray-900'>
            Created Evaluations
          </h2>
          <div className='mt-4 sm:flex sm:items-center'>
            <div className='sm:flex-auto'>
              <p className='mt-2 text-sm text-gray-700'>
                Here is a list of evaluations you have created.
              </p>
            </div>
            <div className='mt-4 sm:ml-16 sm:mt-0 sm:flex-none flex items-center'>
              <input
                type='text'
                placeholder='Search evaluations...'
                value={createdSearchTerm}
                onChange={(e) => setCreatedSearchTerm(e.target.value)}
                className='h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500'
              />
            </div>
          </div>
          {createdEvaluations.data.length > 0 ? (
            <>
              <table className='min-w-full divide-y divide-gray-300 mt-4'>
                <thead>
                  <tr>
                    <th className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900'>
                      Evaluator ID
                    </th>
                    <th className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                      Evaluatee Name
                    </th>
                    <th className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                      Updated At
                    </th>
                    <th className='px-2 py-3.5 text-left text-sm font-semibold text-gray-900'>
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className='bg-white'>
                  {createdEvaluations.data.map((evaluation) => (
                    <tr key={evaluation.id} className='even:bg-gray-50'>
                      <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900'>
                        {evaluation.evaluatorProfile
                          ? `${evaluation.evaluatorProfile.firstName} ${
                              evaluation.evaluatorProfile.lastName || ''
                            }`
                          : 'N/A'}
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                        {evaluation.evaluateeProfile
                          ? `${evaluation.evaluateeProfile.firstName} ${
                              evaluation.evaluateeProfile.lastName || ''
                            }`
                          : 'N/A'}
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                        {dayjs(evaluation.updatedAt).fromNow()}
                      </td>
                      <td className='whitespace-nowrap px-4 py-4 text-sm text-blue-500'>
                        <Link to={`edit/${evaluation.id}/${userType}`}>
                          Edit
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination
                page={createdEvaluationsPage}
                setPage={setCreatedEvaluationsPage}
                itemsPerPage={EVALUATION_LIMIT}
                total={createdEvaluations.totalPages}
              />
            </>
          ) : (
            <p className='mt-4 text-gray-500'>
              No created evaluations found.
            </p>
          )}

          <h2 className='text-base font-semibold leading-6 text-gray-900 mt-8'>
            Received Evaluations
          </h2>
          <div className='mt-4 sm:flex sm:items-center'>
            <div className='sm:flex-auto'>
              <p className='mt-2 text-sm text-gray-700'>
                Here is a list of evaluations you have received.
              </p>
            </div>
            <div className='mt-4 sm:ml-16 sm:mt-0 sm:flex-none flex items-center'>
              <input
                type='text'
                placeholder='Search evaluations...'
                value={receivedSearchTerm}
                onChange={(e) => setReceivedSearchTerm(e.target.value)}
                className='h-9 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500'
              />
            </div>
          </div>
          {receivedEvaluations.data.length > 0 ? (
            <>
              <table className='min-w-full divide-y divide-gray-300 mt-4'>
                <thead>
                  <tr>
                    <th className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900'>
                      Evaluator Name
                    </th>
                    <th className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                      Evaluatee Name
                    </th>
                    <th className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                      Updated At
                    </th>
                    <th className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className='bg-white'>
                  {receivedEvaluations.data.map((evaluation) => (
                    <tr key={evaluation.id} className='even:bg-gray-50'>
                      <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900'>
                        {evaluation.evaluatorProfile
                          ? `${evaluation.evaluatorProfile.firstName} ${
                              evaluation.evaluatorProfile.lastName || ''
                            }`
                          : 'N/A'}
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                        {evaluation.evaluateeProfile
                          ? `${evaluation.evaluateeProfile.firstName} ${
                              evaluation.evaluateeProfile.lastName || ''
                            }`
                          : 'N/A'}
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                        {dayjs(evaluation.updatedAt).fromNow()}
                      </td>
                      <td className='whitespace-nowrap px-4 py-4 text-sm text-green-500'>
                        <Link to={`view/${evaluation.id}/${oppositeUserType}`}>
                          View
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination
                page={receivedEvaluationsPage}
                setPage={setReceivedEvaluationsPage}
                itemsPerPage={EVALUATION_LIMIT}
                total={receivedEvaluations.totalPages}
              />
            </>
          ) : (
            <h2 className='mt-4 text-gray-500'>
              No received evaluations found.
            </h2>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default EvaluationEditViewPage;
