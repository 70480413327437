import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { useHookstate } from '@hookstate/core';
import { globalAuthToken, globalServerMetaInfo, globalUser } from './state';
import { clearToken, getLocalToken } from './services/config';
import { Notification } from '@bctc/components';
import Alert from './components/Alert';
import DashboardPage from './pages/Dashboard';
import LoginPage from './pages/Login';
import DiscordLogin from './pages/ThirdPartyLogin/Discord';
import GithubLogin from './pages/ThirdPartyLogin/Github';
import GoogleLogin from './pages/ThirdPartyLogin/Google';
import ClassesPage from './pages/Classes';
import ClassDetailPage from './pages/Classes/ClassDetail';
import ClassSessionPage from './pages/Classes/ClassSession';
import StudentProfilePage from './pages/Profile/Student';
import TeacherProfilePage from './pages/Profile/Teacher';
import SettingsPage from './pages/Settings';
import ClassSessionManagePage from './pages/Classes/ClassSessionManagePage';
import GradingPage from './pages/Classes/Grading';
import AnnouncementsPage from './pages/Announcements';
import AuthService from './services/auth';
import GradesPage from './pages/Grades';
import ForgotPasswordPage from './pages/ForgotPassword';
import PageNotFound from './components/PageNotFound';
import NotificationPage from './pages/Notification';
import SurveyPage from './pages/Survey';
import { Persistence } from '@hookstate/persistence';
import Maintenance from './components/Maintenance';
import { AuthProvider } from 'oidc-react';
import SSOCallbackPage from './pages/Login/SSOCallback';
import SSOLink from './pages/Login/SSOLink';
import LogoutComponent from './components/Logout';
import SSOSignInPage from './pages/Login/SSOSignin';
import FileListPage from './pages/LiveSession/FileList';
import LiveSessionPage from './pages/LiveSession';
import PlacementExamPage from './pages/PlacementExam';
import { HomeworkHelperPage } from './pages/HomeworkHelper';
import EvaluationPage from './pages/Evaluation/EvalutaionsPage';
import LessonMaterialPage from './pages/LessonMaterial/index';
import EvaluationEditViewPage from './pages/Evaluation/EvaluationTablePage';
import EvaluationEditPage from './pages/Evaluation/EvaluationEditPage';
import EvaluationViewPage from './pages/Evaluation/EvaluationViewPage';

const App: React.FC = () => {
  const authToken = useHookstate(globalAuthToken);
  const userStateInfo = useHookstate(globalUser);
  const loading = useHookstate(true);
  const [isMaintenance, setMaintenance] = useState(false);

  useEffect(() => {
    const checkServerStatus = async () => {
      try {
        const status = await AuthService.checkServerStatus();
        globalServerMetaInfo.set(status.data);
        if (status.status !== 200) {
          localStorage.clear();
          sessionStorage.clear();
          setMaintenance(true);
        }
      } catch (err) {
        setMaintenance(true);
      }
    };
    checkServerStatus();
  }, []);

  useEffect(() => {
    if (!JSON.parse(localStorage.getItem('state.user'))) {
      clearToken();
      const pageNotRequireLogin = ['/login', '/forgotpassword'];
      if (!pageNotRequireLogin.includes(window.location.pathname)) {
        window.location.href = '/login';
        localStorage.setItem('previousPage', window.location.pathname);
      }
    }
    if (getLocalToken()) {
      AuthService.getUserInfoByToken(getLocalToken())
        .then(({ data }) => {
          if (data) {
            globalAuthToken.attach(Persistence('state.authToken'));
          }
          return;
        })
        .catch((err) => {
          clearToken();
          return;
        });
    }
    loading.set(false);
  }, []);

  const mustNotBeLoggedIn = (Component: React.FC) => {
    if (loading.value) {
      return null;
    }
    if (userStateInfo.value === null) {
      return <Component />;
    }
    if (authToken.value && getLocalToken()) return <Redirect to='/class' />;
    else {
      return <Component />;
    }
  };

  const oidcConfig = {
    onSignIn: (userData) => {
      return;
    },
    authority: 'https://sso.thefuturesphere.com/application/o/fs-portal/',
    clientId: 'e4e294b1ffc02ada118fb9316301bbe0b7ce8dc1',
    clientSecret:
      '828bbb064442e706b4c1d5a29313260df7c63ea0d0c49d30ac97a7c069520f9f902bff541556e219e5a1a44ba2c8029574c59381d90e0713a61c862feb045558',
    redirectUri: `${window.location.origin}/sso/callback`,
    scope: 'openid profile email user:pk',
    responseType: 'code',
    autoSignIn: false,
  };

  return (
    <AuthProvider {...oidcConfig}>
      <div className='App'>
        {isMaintenance ? (
          <Maintenance />
        ) : (
          <>
            <Notification />
            <Alert />
            <Router>
              <Switch>
                <Route exact path='/' component={DashboardPage} />
                <Route exact path='/logout' component={LogoutComponent} />
                <Route
                  exact
                  path='/login'
                  render={() => mustNotBeLoggedIn(LoginPage)}
                />
                <Route exact path='/sso/signin' component={SSOSignInPage} />
                <Route exact path='/sso/callback' component={SSOCallbackPage} />
                <Route exact path='/sso/link' component={SSOLink} />
                <Route exact path='/placement' component={PlacementExamPage} />
                <Route
                  exact
                  path='/announcements'
                  component={AnnouncementsPage}
                />
                <Route
                  exact
                  path='/forgotpassword'
                  render={() => mustNotBeLoggedIn(ForgotPasswordPage)}
                />
                <Route exact path='/discord' component={DiscordLogin} />
                <Route exact path='/github' component={GithubLogin} />
                <Route exact path='/google' component={GoogleLogin} />
                <Route exact path='/class' component={ClassesPage} />
                <Route exact path='/class/:id' component={ClassDetailPage} />
                <Route
                  exact
                  path='/class/:id/:session'
                  component={ClassSessionPage}
                />
                <Route
                  exact
                  path='/class/:id/:session/manage'
                  component={ClassSessionManagePage}
                />
                <Route path='/homework-helper' component={HomeworkHelperPage} />
                <Route
                  exact
                  path='/class/:id/:session/submission/:student'
                  component={GradingPage}
                />
                <Route exact path='/grades' component={GradesPage} />
                <Route exact path='/grades/:studentId' component={GradesPage} />
                <Route
                  exact
                  path='/student/:id'
                  component={StudentProfilePage}
                />
                <Route
                  exact
                  path='/teacher/:id'
                  component={TeacherProfilePage}
                />
                <Route exact path='/settings' component={SettingsPage} />
                <Route
                  exact
                  path='/notification'
                  component={NotificationPage}
                />
                <Route
                  exact
                  path='/survey/:classId/:studentId'
                  component={SurveyPage}
                />
                <Route
                  exact
                  path='/live/:sessionId'
                  component={LiveSessionPage}
                />
                <Route exact path='/files' component={FileListPage} />
                <Route
                  exact
                  path='/evaluation/:id'
                  component={EvaluationEditViewPage}
                ></Route>
                <Route
                  exact
                  path='/evaluation/edit/:id/:evaluatorType'
                  component={EvaluationEditPage}
                />
                <Route
                  exact
                  path='/evaluation/view/:id/:evaluatorType'
                  component={EvaluationViewPage}
                />
                <Route
                  exact
                  path='/evaluation/:classSessionId/:evaluatorId/:evaluateeId/:evaluatorType'
                  component={EvaluationPage}
                />
                <Route
                  exact
                  path='/lessonMaterials/:id'
                  component={LessonMaterialPage}
                />
                <Route
                  path='*'
                  render={(props) => <PageNotFound {...props} />}
                />
              </Switch>
            </Router>
          </>
        )}
        <Notification />
      </div>
    </AuthProvider>
  );
};

export default App;
