import React from 'react';
import classNames from 'classnames';
interface props {
  terminalTab: 'output' | 'graph';
  setTerminalTab: React.Dispatch<React.SetStateAction<'output' | 'graph'>>;
  terminalContent: string;
  showOutputDot: boolean;
  setShowOutputDot: React.Dispatch<React.SetStateAction<boolean>>;
}
const Terminal: React.FC<props> = ({
  terminalTab,
  setTerminalTab,
  terminalContent,
  showOutputDot,
  setShowOutputDot,
}: props) => {
  return (
    <div className='h-full w-full'>
      <div className='flex'>
        <button
          onClick={() => setTerminalTab('graph')}
          className={
            'px-4 py-1' +
            (terminalTab === 'graph'
              ? ' bg-white rounded-t-md'
              : ' text-gray-400')
          }
        >
          Graph
        </button>
        <button
          onClick={() => {
            setShowOutputDot(false);
            setTerminalTab('output');
          }}
          className={
            'px-4 py-1 flex items-center' +
            (terminalTab === 'output'
              ? ' bg-white rounded-t-md'
              : ' text-gray-400')
          }
        >
          Output
          {showOutputDot && (
            <div className='relative h-full ml-0.5'>
              <div className='absolute bg-blue-500 w-2.5 h-2.5 rounded-full animate-ping' />
              <div className='absolute bg-blue-500 w-2.5 h-2.5 rounded-full' />
            </div>
          )}
        </button>
      </div>

      <div className='bg-white w-full h-full'>
        <div
          id='turtle-canvas'
          className={
            'h-full w-full ' + (terminalTab === 'graph' ? 'visible' : 'hidden')
          }
        ></div>
        {terminalTab === 'output' && (
          <div
            className={classNames(
              'h-full p-4 prose whitespace-pre-wrap rounded-lg pb-10 overflow-scroll ',
              terminalContent.includes('ERR:')
                ? 'text-red-300'
                : 'text-gray-800'
            )}
          >
            {terminalContent.includes('ERR:')
              ? terminalContent.replace('ERR:', '')
              : terminalContent}
          </div>
        )}
      </div>
    </div>
  );
};
export default Terminal;
