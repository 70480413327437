/* eslint-disable react/prop-types */
import { useState } from '@hookstate/core';
import classNames from 'classnames';
import React, { ReactNode, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import Navbar from '../../components/Navbar';
import { getLocalToken } from '../../services/config';
import { globalAuthToken, globalServerMetaInfo } from '../../state';

interface Props {
  children: ReactNode;
  navOnly?: boolean;
  bg?: boolean;
}

const Layout: React.FC<Props> = ({ children, navOnly, bg }) => {
  const authToken = useState(globalAuthToken);
  const serverMetaInfo = useState(globalServerMetaInfo);
  if (!authToken.value && !getLocalToken()) {
    return <Redirect to='/login' />;
  }
  const year = useMemo(() => new Date().getFullYear(), []);

  return (
    <div className='min-h-screen bg-gray-100'>
      {/* background in the back */}
      {bg ? (
        <div className='bg-gradient-to-r from-blue-900 to-cyan-600 h-151 w-full absolute top-0' />
      ) : null}

      <Navbar navOnly={navOnly} />

      <main
        className={classNames('', {
          '-mt-28 lg:-mt-44 pb-0 static': navOnly,
          '-mt-16 lg:-mt-24 pb-8 relative': !navOnly,
        })}
      >
        {children}
      </main>

      <footer>
        <div className='max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl'>
          <div className='border-t border-gray-200 py-8 text-sm text-gray-500 text-center sm:text-left'>
            {serverMetaInfo.get() && (
              <p>
                Portal Build:{' '}
                <span>v{import.meta.env.PACKAGE_VERSION || 'N/A'}, </span>
                <span>Revision: {__COMMIT_HASH__ || 'N/A'} | </span>
                <span>API Gateway: {serverMetaInfo.get()?.host}, </span>
                <span>Build: v{serverMetaInfo.get().version}, </span>
                <span>Revision: {serverMetaInfo.get().commit}, </span>
                <span>
                  {serverMetaInfo.value.caching ? 'Using Cached' : 'No Caching'}{' '}
                  |
                </span>
                <span>Collection ID: </span>
              </p>
            )}

            <p>
              <span className='block sm:inline'>
                &copy; {year} Future Sphere Corporation.
              </span>{' '}
              <span className='block sm:inline'>All rights reserved.</span>
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Layout;
