import { AttendanceStatus } from '@prisma/client';
import { AxiosResponse } from 'axios';
import a from './config';
import { Criteria } from '../types';
import { co } from '@fullcalendar/core/internal-common';

interface INeededClassInfo {
  templateId: string;
  cohort: number;
  title: string;
  classLength: number;
  days: number[];
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  students: string[];
  teachers: number[];
  zoomId: string;
  zoomLink: string;
  zoomPassword: string;
}

const addStudentToClass = (payload: {
  cohortId: number;
  studentId: string;
}): Promise<AxiosResponse> => {
  return a.post('/cohorts/enroll', payload);
};

const replaceInstructor = (
  classId: number,
  instructor: number
): Promise<AxiosResponse> => {
  return a.put(`/cohorts/${classId}`, { instructor });
};

const getClassInfoByClassId = (classId: number): Promise<AxiosResponse> => {
  return a.get(`/cohorts/${classId}`);
};

export const fetchCourses = (params?: {
  searchQuery?: string;
  page?: number;
  pageSize?: number;
}): Promise<AxiosResponse> => {
  return a.get('/courses', { params: { ...params, locationId: 'online' } });
};

const getStudentClassInfo = (
  classId: string,
  studentId: string
): Promise<AxiosResponse> =>
  a.get('/classes/info/student', { params: { classId, studentId } });

const createNewClass = (data: INeededClassInfo): Promise<AxiosResponse> => {
  return a.post('/cohorts', data);
};

const editClass = (id: number, data: any): Promise<AxiosResponse> => {
  return a.put('/cohorts/' + id, data);
};

const getRecordingsByClassId = (classId: string): Promise<AxiosResponse> =>
  a.get('recordings/class', { params: { classId } });

const getRecordingsByClassIdAndClassIndex = (
  classId: string,
  classIndex: number
): Promise<AxiosResponse> =>
  a.get('recordings/classIndex', { params: { classId, classIndex } });

const handleGetClassTemplates = (): Promise<AxiosResponse> => {
  return a.get('/courses/');
};

const fetchClassScores = (classId: string): Promise<AxiosResponse> => {
  return a.get(`/classes/roster`, {
    params: { classId },
  });
};

const removeStudent = (
  cohortId: number,
  studentId: string
): Promise<AxiosResponse> => {
  return a.delete('/cohorts/unenroll', { data: { cohortId, studentId } });
};

const removeTeacher = (
  classId: string,
  teacherId: string
): Promise<AxiosResponse> => {
  return a.delete('/classes/teachers/remove', { data: { classId, teacherId } });
};
const fetchGradingTimeLine = (
  id: string,
  studentId: string
): Promise<AxiosResponse> =>
  a.get(`/cohorts/grading/${id}?studentId=${studentId}`);

const getLatestClassCohort = (templateId: string): Promise<AxiosResponse> => {
  return a.get('/cohorts/latest', { params: { templateId } });
};

const getDisabledClassSessions = (classId: string): Promise<AxiosResponse> => {
  return a.get('/disabledAssignments/class', { params: { classId } });
};

const getClassTitles = (classId: string): Promise<AxiosResponse> => {
  return a.get('/classes/titles', { params: { classId } });
};

const enableClassSession = (
  classId: string,
  classIndex: number
): Promise<AxiosResponse> => {
  return a.delete('/disabledAssignments', { data: { classId, classIndex } });
};

const disableClassSession = (
  classId: string,
  classIndex: number
): Promise<AxiosResponse> => {
  return a.post('/disabledAssignments', { classId, classIndex });
};

const fetchCohortById = (cohortId: number): Promise<AxiosResponse> => {
  return a.get(`/cohorts/${cohortId}`);
};

const updateClassEndDate = (
  classId: number,
  endDate: Date
): Promise<AxiosResponse> => {
  return a.put(`/cohorts/${classId}`, { endDate });
};

export const getSessionByCohortAndIndex = (
  cohortId: number,
  index: number
): Promise<AxiosResponse> => {
  return a.get(`/cohorts/${cohortId}/${index}`);
};

const fetchCohortByTeacherId = (
  instructorId: number | Array<number>
): Promise<AxiosResponse> => {
  return a.get('/cohorts', { params: { instructorId } });
};

const getClassByTitle = (keyword: string): Promise<AxiosResponse> => {
  return a.get('/classes/search/title', { params: { keyword } });
};

const getAllCohorts = (): Promise<AxiosResponse> => {
  return a.get('/cohorts?take=9999');
};
const getClassCodaLink = (
  classId: string,
  classIndex: number
): Promise<AxiosResponse> => {
  return a.get('/classes/codaLink', { params: { classId, classIndex } });
};

const deleteClassById = (classId: string): Promise<AxiosResponse> => {
  return a.delete('/cohorts/' + classId);
};

export const updateSession = (
  sessionId: string,
  data: {
    recording?: string | null;
    disabled?: boolean;
  }
): Promise<AxiosResponse> => {
  return a.put(`/cohorts/session/${sessionId}`, data);
};

export const markAttendance = (
  sessionId: string,
  studentId: string,
  status: AttendanceStatus,
  teacherId: number
): Promise<AxiosResponse> => {
  return a.post(`/cohorts/attendance/${sessionId}`, {
    student: studentId,
    status,
    teacherId,
  });
};

export const getWorkstationAccessCode = (): Promise<AxiosResponse> => {
  return a.get('/rooms/workstation-code');
};

export const getAllStudentIdForEvaluations = (
  cohortId: number,
  lessonIndex: number
): Promise<AxiosResponse> => {
  return a.get(`/evaluations/${cohortId}/${lessonIndex}`);
};

export const fetchSingleEvaluatedSessionStatusForStudent = (
  classSessionId: string,
  studentId: string,
  teacherId: string
): Promise<AxiosResponse> => {
  return a.get(`/evaluations/${classSessionId}/${studentId}/${teacherId}`);
};

export const createEvaluation = (
  classSessionId: string,
  evaluatorId: string,
  evaluateeId: string,
  metrics: Array<{ criteria: Criteria; rating?: number; comments?: string }>
): Promise<AxiosResponse> => {
  return a.post(
    `/evaluations/${classSessionId}/${evaluatorId}/${evaluateeId}`,
    { metrics }
  );
};
export const fetchLessonByCourseId = (
  courseId: string
): Promise<AxiosResponse> => {
  return a.get('/lessons/byCourseId/' + courseId);
};

export const addSessionToCohort = ({
  cohortId,
  lessonId,
}: {
  cohortId: number;
  lessonId: number;
}): Promise<AxiosResponse> => {
  return a.post('/cohorts/addSession', { cohortId, lessonId });
};

const ClassService = {
  addStudentToClass,
  getClassInfoByClassId,
  getStudentClassInfo,
  createNewClass,
  getRecordingsByClassId,
  handleGetClassTemplates,
  fetchClassScores,
  replaceInstructor,
  removeStudent,
  removeTeacher,
  getLatestClassCohort,
  getDisabledClassSessions,
  enableClassSession,
  disableClassSession,
  getClassTitles,
  updateClassEndDate,
  getClassByTitle,
  editClass,
  getClassCodaLink,
  getRecordingsByClassIdAndClassIndex,
  fetchGradingTimeLine,
  fetchCohortByTeacherId,
  deleteClassById,
  fetchCohortById,
  getAllCohorts,
  getWorkstationAccessCode,
  fetchSingleEvaluatedSessionStatusForStudent,
  createEvaluation,
  fetchLessonByCourseId,
  addSessionToCohort,
};

export default ClassService;
