import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useHookstate } from '@hookstate/core';
import Layout from '../Layout';
import a from '../../services/config';
import { globalNotifications } from '../../state';

const EvaluationViewPage: React.FC = () => {
  const notification = useHookstate(globalNotifications);
  const history = useHistory();
  const [id, evaluatorType] = window.location.href
    .split('/view/')[1]
    .split('/');

  const [evaluation, setEvaluation] = useState(null);
  const [allCriteria, setAllCriteria] = useState([]);
  const [error, setError] = useState<string | null>(null);
  const [alert, setAlert] = useState({ show: false, message: '', type: '' });

  const fetchEvaluation = async () => {
    try {
      const { data } = await a.get(`evaluations/edit/${id}`);
      setEvaluation(data);
    } catch (error) {
      setError('Failed to fetch evaluation data. Please try again later.');
      console.error('Error fetching evaluation:', error);
    }
  };

  const fetchSurvey = async () => {
    try {
      const { data } = await a.get(`/evaluations/${evaluatorType}`);
      setAllCriteria(data);
    } catch (error) {
      setError('Failed to fetch survey data. Please try again later.');
      console.error('Error fetching survey:', error);
    }
  };

  useEffect(() => {
    fetchEvaluation();
    fetchSurvey();
  }, [id, evaluatorType]);

  if (!evaluation || !allCriteria.length) {
    return <div>Loading...</div>;
  }

  return (
    <Layout bg>
      <div className='container mx-auto px-4 py-8 max-w-4xl'>
        <div className='bg-white rounded-lg shadow-lg p-8'>
          {alert.show && (
            <div
              className={`mb-4 p-4 rounded ${
                alert.type === 'error'
                  ? 'bg-red-100 text-red-700'
                  : 'bg-green-100 text-green-700'
              }`}
            >
              {alert.message}
            </div>
          )}
          <h1 className='text-3xl font-bold mb-6 text-center text-gray-800'>
            View Evaluation
          </h1>
          <div className='mb-6'>
            <p className='text-md'>
              Date: {new Date(evaluation.updatedAt).toLocaleDateString()}
            </p>
          </div>
          {evaluation.metrics.map((metric) => {
            const criteriaObj = allCriteria.find(c => c.criteria === metric.criteria);
            return (
              <div
                key={metric.criteria}
                className='mb-8 p-6 rounded-lg shadow-md bg-gray-100'
              >
                <h2 className='text-xl font-semibold mb-4 text-gray-800'>
                  {metric.criteria}
                </h2>
                <div className='mb-4 grid grid-cols-5 gap-4'>
                  {[0, 1, 2, 3, 4].map((index) => (
                    <div key={index} className='text-center'>
                      <div
                        className={`w-full py-2 px-4 rounded-md ${
                          metric.rating === index
                            ? 'bg-blue-500 text-white'
                            : 'bg-gray-200 text-gray-700'
                        }`}
                      >
                        {index + 1}
                      </div>
                      <p className='mt-2 text-sm text-gray-600'>
                        
                        {criteriaObj?.rating[index]}
                      </p>
                    </div>
                  ))}
                </div>
                {metric.comments && (
                  <div className='mt-4'>
                    <h3 className='font-semibold'>Comments:</h3>
                    <p className='text-gray-700'>{metric.comments}</p>
                  </div>
                )}
              </div>
            );
          })}
          <div className='text-center'>
            <button
              className='bg-gray-500 text-white px-6 py-2 rounded-md hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50'
              onClick={() => history.goBack()}
            >
              Back
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default EvaluationViewPage;