import React from 'react';
import FullScreenLoading from '../FullscreenLoading';
import { logoUrl } from '../../constants/assets';
interface Props {}
const Maintenance: React.FC<Props> = () => {
  return (
    <div className='absolute top-0 bottom-0 left-0 right-0 z-50'>
      <div className='min-h-screen bg-white flex'>
        <div className='flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24'>
          <div>
            <img className='h-20 w-auto' src={logoUrl} alt='Workflow' />
          </div>
          <p className='text-gray-800 mt-3 text-2xl'>
            Student Portal is under maintenance.
          </p>
          <p className='text-gray-500 mt-1'>
            We are currently performing scheduled maintenance. We will be back
            shortly.
          </p>
        </div>
        <div className='hidden lg:block relative w-0 flex-1'>
          <FullScreenLoading />
        </div>
      </div>
    </div>
  );
};

export default Maintenance;
