import React, { useCallback } from 'react';
import Feed from '../../components/Feed';
import axios from 'axios';
import { Spinner } from '@bctc/components';

const Feeds: React.FunctionComponent = () => {
  const [news, setNews] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const getNews = useCallback(async () => {
    setLoading(true);
    const response = await axios.get(
      'https://hacker-news.firebaseio.com/v0/topstories.json?print=pretty'
    );
    const news = response.data;
    const newsList = [];
    for (let i = 0; i < 20; i++) {
      const newsItem = await axios.get(
        `https://hacker-news.firebaseio.com/v0/item/${news[i]}.json?print=pretty`
      );
      const newsData = newsItem.data;
      newsList.push({
        id: newsData.id,
        author: newsData.by,
        date: newsData.time,
        title: newsData.title,
        url: newsData.url,
      });
    }
    setNews(newsList);
    setLoading(false);
  }, []);

  React.useEffect(() => {
    getNews();
  }, [getNews]);

  return (
    <section
      className='lg:col-span-9 xl:col-span-6 rounded-md'
      aria-labelledby='profile-overview-title'
    >
      <>
        <h1 className='text-2xl font-bold text-gray-900 dark:text-gray-100 mb-2'>
          Hacker News
        </h1>
        {loading ? (
          <div className='flex justify-center items-center py-12 w-full'>
            <Spinner color='dark' className='h-8 w-8' />
            <p className='ml-2 text-gray-500 dark:text-gray-400 font-medium text-sm'>
              Loading stories...
            </p>
          </div>
        ) : (
          <ul
            role='list'
            className='gap-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3'
          >
            {news.map((v, i) => (
              <Feed {...v} key={i} />
            ))}
          </ul>
        )}
      </>
    </section>
  );
};

export default Feeds;
