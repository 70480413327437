/* eslint-disable react/prop-types */
import { Menu, Popover, Transition } from '@headlessui/react';
import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline';
import { useHookstate } from '@hookstate/core';
import { Persistence } from '@hookstate/persistence';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { placeholderAvatar, whiteLogoUrl } from '../../constants/assets';
import { classNames } from '../../helpers/className';
import { clearToken } from '../../services/config';
import { globalBreadcrumbs, globalRole, globalUser } from '../../state';
import Breadcrumbs, { ILink } from '../Breadcrumbs';
import { Fragment } from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import NotificationService, {
  fetchNotifications,
} from '../../services/notification';
import {
  TrashIcon,
  NewspaperIcon,
  InformationCircleIcon,
  ClipboardCheckIcon,
  AnnotationIcon,
} from '@heroicons/react/outline';
import { globalAuthToken } from '../../state';
import { PortalNotification } from '@prisma/client';
import UserService from '../../services/user';
import { error } from '@bctc/components';
import { GUEST_STUDENT_ID, SUPPORTED_LANGUAGES } from '../../constants';
interface Props {
  navOnly?: boolean;
}
import LiveSessionService from '../../services/liveSession';
import { notify } from '@bctc/components';
import { CodeIcon } from '@heroicons/react/outline';

const Navbar: React.FC<Props> = ({ navOnly }) => {
  dayjs.extend(relativeTime);
  const user = useHookstate(globalUser);
  user.attach(Persistence('state.user'));
  const role = useHookstate(globalRole);
  role.attach(Persistence('state.role'));
  const authToken = useHookstate(globalAuthToken);
  const breadcrumbs = useHookstate(globalBreadcrumbs);
  const history = useHistory();
  const path =
    history.location.pathname === '/settings'
      ? role.value === 'student'
        ? '/settings#Profile'
        : '/settings#Account'
      : history.location.pathname;
  const isOnNestedPage = path.split('/').length > 2;
  React.useEffect(() => {
    const checkUserStatus = async () => {
      if (!user.value?.id) return;
      const { data: response } = await UserService[
        role.value === 'student' ? 'fetchStudentByObjectId' : 'fetchTeacherById'
      ](user.value.id as never);
      if (!response) return;
      if (response.disabled) {
        authToken.set(null);
        clearToken();
        window.location.href = '/login';
      }
    };
    checkUserStatus();
  }, []);

  const navigation = [
    { name: 'Home', href: '/' },
    { name: 'Classes', href: '/class' },
    { name: 'Files', href: '/files' },
    role.value == 'teacher'
      ? null
      : { name: 'My Grades', href: `/grades/${user?.value?.id}` },
    { name: 'Evaluations', href: `/evaluation/${user?.value?.id}` },
    {
      name: 'Settings',
      href: role.value == 'teacher' ? '/settings#Account' : '/settings#Profile',
    },
    { name: 'Announcements', href: '/announcements' },
    { name: '🤖AI Homework Helper', href: '/homework-helper' },
  ];

  const userNavigation = [
    {
      name: 'Your Profile',
      href: `/${globalRole.value}/${user?.value?.id}`,
    },
    {
      name: 'Settings',
      href: role.value == 'teacher' ? '/settings#Account' : '/settings#Profile',
    },
    { name: 'Sign out', href: '/logout' },
  ];
  const [notifications, setNotifications] = useState<PortalNotification[]>([]);
  const [notificationCounter, setNotificationsCounter] = useState(0);
  const getNotifications = async () => {
    try {
      const { data: response } = await fetchNotifications(
        user.value?.profile.id
      );
      if (!response) return;
      setNotifications(response.data);
      setNotificationsCounter(response.total);
    } catch (err) {
      error({
        title: 'Failed to fetch notifications',
      });
    }
  };
  const handleReadAllNotification = async () => {
    await NotificationService.markAllNotificationAsRead(user.value?.profile.id);
    setNotifications([]);
  };
  const handleRead = async (notificationId: string) => {
    await NotificationService.markNotificationAsRead(notificationId);
    getNotifications();
  };
  React.useEffect(() => {
    getNotifications();
  }, []);

  const createFillName = String(dayjs()) + '.' + SUPPORTED_LANGUAGES[0];
  const createNewFile = async () => {
    try {
      const { data } = await LiveSessionService.createNewFile({
        filename: createFillName,
        ownerId: user.value.profile.id,
      });
      if (!data.error) {
        notify({
          title: 'File created',
          description: `File ${createFillName} created successfully`,
        });
        navigator.clipboard.writeText(data.joinCode);
        history.push(`/live/${data.id}`);
      } else throw data.error;
    } catch (err) {
      error(
        typeof err === 'string'
          ? err
          : `Couldn't create file at the moment, please try again later.`
      );
    }
  };

  const handleTypeIcon = (type: string) => {
    switch (type) {
      case 'SUBMISSION':
        return (
          <NewspaperIcon
            className='flex-shrink-0 w-6 h-6 text-blue-600'
            aria-hidden='true'
          />
        );
      case 'ANNOUNCEMENT':
        return (
          <InformationCircleIcon
            className='flex-shrink-0 w-6 h-6 text-blue-600'
            aria-hidden='true'
          />
        );
      case 'GRADING':
        return (
          <ClipboardCheckIcon
            className='flex-shrink-0 w-6 h-6 text-blue-600'
            aria-hidden='true'
          />
        );
      case 'COMMENT':
        return (
          <AnnotationIcon
            className='flex-shrink-0 w-6 h-6 text-blue-600'
            aria-hidden='true'
          />
        );
    }
  };
  const breadcrumbValue = breadcrumbs.value as ILink[];

  const profileFallBackImage =
    user?.value?.profile?.avatar || placeholderAvatar;

  return (
    <Popover as='header' className='pb-24'>
      {({ open }) => (
        <>
          <div className='relative max-w-3xl px-4 mx-auto z-1 sm:px-6 lg:max-w-7xl lg:px-8'>
            <div className='relative flex flex-wrap items-center justify-center py-1 lg:justify-between lg:py-0'>
              {/* Logo */}
              <div className='absolute left-0 flex-shrink-0 top-6 lg:static'>
                <Link to='/'>
                  <span className='sr-only'>Future Sphere</span>
                  <img src={whiteLogoUrl} className='w-auto h-9' alt='Logo' />
                </Link>
              </div>

              {/* Right section on desktop */}
              <div className='hidden lg:ml-4 lg:flex lg:items-center lg:py-5 lg:pr-0.5 relative'>
                {/* show student id only if role is student */}
                {role.value == 'student' ? (
                  GUEST_STUDENT_ID === user?.value?.id ? (
                    <p className='text-sm text-white'>Guest Student</p>
                  ) : (
                    <p className='text-sm text-white'>
                      Student #{user?.value?.id}
                    </p>
                  )
                ) : null}
                <div className='ml-4 mr-1'>
                  <button
                    className='text-white font-bold'
                    onClick={() => createNewFile()}
                  >
                    <CodeIcon className='mt-1 h-5 w-5' />
                  </button>
                </div>
                <Popover as='div' className='flex-shrink-0 ml-1'>
                  {({ open }) => (
                    <>
                      <div>
                        <Popover.Button
                          type='button'
                          className='flex-shrink-0 p-1 text-white rounded-full hover:text-white hover:bg-white hover:bg-opacity-10 focus:outline-none'
                        >
                          <span className='sr-only'>View notifications</span>
                          <div className='relative'>
                            <BellIcon className='w-6 h-6' aria-hidden='true' />
                            {notifications && notifications.length ? (
                              <div className='absolute top-0 right-0 w-2 h-2 bg-red-600 rounded-full'></div>
                            ) : null}
                          </div>
                        </Popover.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter='transition ease-out duration-200'
                        enterFrom='opacity-0 translate-y-1'
                        enterTo='opacity-100 translate-y-0'
                        leave='transition ease-in duration-150'
                        leaveFrom='opacity-100 translate-y-0'
                        leaveTo='opacity-0 translate-y-1'
                      >
                        <Popover.Panel className='absolute right-0 z-10 max-w-md px-2 mt-3 transform w-96 sm:px-0'>
                          <div className='overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5'>
                            <div className='relative grid gap-6 p-6 bg-white sm:gap-8'>
                              {notifications && notifications.length
                                ? notifications.map((item) => (
                                    <div key={item.id} className='relative'>
                                      <a
                                        onClick={() => handleRead(item.id)}
                                        href={item.url}
                                        className='flex items-center p-3 -m-3 transition duration-150 ease-in-out rounded-lg cursor-pointer hover:bg-gray-50'
                                      >
                                        {handleTypeIcon(item.purpose)}
                                        <div className='w-full ml-4'>
                                          <p className='mt-1 text-gray-500 text-md'>
                                            {item.content}
                                          </p>
                                          <p className='flex items-center justify-between mt-1 text-xs text-gray-500'>
                                            {dayjs(item.createdAt).fromNow()}
                                          </p>
                                        </div>
                                      </a>
                                      <a
                                        onClick={() => handleRead(item.id)}
                                        className='absolute bottom-0 text-sm text-blue-400 cursor-pointer right-4 hover:text-blue-500'
                                      >
                                        read
                                      </a>
                                    </div>
                                  ))
                                : 'No notification at this time.'}
                              {notificationCounter &&
                              notificationCounter > 5 ? (
                                <a
                                  type='button'
                                  href='/notification'
                                  className='cursor-pointer justify-center inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-blue-700 bg-blue-100 hover:bg-blue-200 focus:outline-none'
                                >
                                  View More
                                </a>
                              ) : null}
                            </div>
                            {notifications && notifications.length ? (
                              <div className='px-8 py-5 pl-6 space-y-6 bg-gray-50 sm:flex sm:space-y-0 sm:space-x-10'>
                                <div className='flow-root'>
                                  <a
                                    onClick={handleReadAllNotification}
                                    className='flex items-center p-3 -m-3 text-base font-medium text-gray-900 transition duration-150 ease-in-out rounded-md cursor-pointer hover:bg-gray-100'
                                  >
                                    <TrashIcon
                                      className='flex-shrink-0 w-5 h-5 text-gray-500'
                                      aria-hidden='true'
                                    />
                                    <span className='ml-2 text-sm text-gray-500'>
                                      Mark all as read
                                    </span>
                                  </a>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>

                {/* Profile dropdown */}
                <Menu as='div' className='flex-shrink-0 ml-4'>
                  {({ open }) => (
                    <>
                      <div>
                        <Menu.Button className='flex text-sm bg-white rounded-full ring-2 ring-white ring-opacity-20 focus:outline-none focus:ring-opacity-100'>
                          <span className='sr-only'>Open user menu</span>
                          <img
                            className='w-8 h-8 rounded-full'
                            src={profileFallBackImage}
                            onError={(e) =>
                              (e.currentTarget.src = placeholderAvatar)
                            }
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        show={open}
                        as={React.Fragment}
                        leave='transition ease-in duration-75'
                        leaveFrom='transform opacity-100 scale-100'
                        leaveTo='transform opacity-0 scale-95'
                      >
                        <Menu.Items
                          static
                          className='absolute w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg -right-2 ring-1 ring-black ring-opacity-5 focus:outline-none'
                        >
                          {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <Link
                                  to={item.href}
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700'
                                  )}
                                >
                                  {item.name}
                                </Link>
                              )}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu>
              </div>

              {navOnly ? null : (
                <div className='w-full py-5 lg:border-t lg:border-white lg:border-opacity-20'>
                  <div className='flex items-center justify-between'>
                    {/* Left nav */}
                    {isOnNestedPage && breadcrumbValue.length ? (
                      <div className='hidden lg:block'>
                        <Breadcrumbs links={breadcrumbs.value as ILink[]} />
                      </div>
                    ) : null}
                    <div className='hidden px-12 lg:px-0 lg:block'>
                      <nav className='flex items-center space-x-2 xl:space-x-4'>
                        {navigation.map((item) => {
                          if (item !== null) {
                            const current = item.href === path;
                            return (
                              <Link
                                key={item.name}
                                to={item.href}
                                className={`
                                  ${
                                    current ? 'text-white' : 'text-blue-200'
                                  } text-sm font-medium rounded-md bg-white whitespace-nowrap bg-opacity-0 px-1 py-2 hover:bg-opacity-10`}
                                aria-current={current ? 'page' : undefined}
                              >
                                {item.name}
                              </Link>
                            );
                          }
                        })}
                      </nav>
                    </div>
                  </div>
                </div>
              )}

              {/* Menu button */}
              <div className='absolute right-0 flex-shrink-0 top-5 lg:hidden'>
                {/* Mobile menu button */}
                <Popover.Button className='inline-flex items-center justify-center p-2 text-white bg-transparent rounded-md hover:text-white hover:bg-white hover:bg-opacity-10 focus:outline-none'>
                  <span className='sr-only'>Open main menu</span>
                  {open ? (
                    <XIcon className='block w-6 h-6' aria-hidden='true' />
                  ) : (
                    <MenuIcon className='block w-6 h-6' aria-hidden='true' />
                  )}
                </Popover.Button>
              </div>
            </div>
          </div>

          <Transition.Root show={open} as={React.Fragment}>
            <div className='lg:hidden'>
              <Transition.Child
                as={React.Fragment}
                enter='duration-150 ease-out'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='duration-150 ease-in'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <Popover.Overlay
                  static
                  className='fixed inset-0 bg-black bg-opacity-25 z-1'
                />
              </Transition.Child>

              <Transition.Child
                as={React.Fragment}
                enter='duration-150 ease-out'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='duration-150 ease-in'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Popover.Panel
                  focus
                  static
                  className='absolute inset-x-0 top-0 w-full max-w-3xl p-2 mx-auto transition origin-top transform z-1'
                >
                  <div className='bg-white divide-y divide-gray-200 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5'>
                    <div className='pt-3 pb-2'>
                      <div className='flex items-center justify-between px-4'>
                        <div>
                          <img
                            className='w-auto h-8 cursor-pointer'
                            src='https://res.cloudinary.com/dtgh01qqo/image/upload/v1578112543/FutureSphere/logo%E9%95%BF%E6%96%B9%E5%BD%A2%E9%80%8F%E6%98%8E%E8%83%8C%E6%99%AF.png'
                            alt='Workflow'
                          />
                        </div>
                        <div className='-mr-2'>
                          <Popover.Button className='inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500'>
                            <span className='sr-only'>Close menu</span>
                            <XIcon className='w-6 h-6' aria-hidden='true' />
                          </Popover.Button>
                        </div>
                      </div>
                      <div className='px-2 mt-3 space-y-1'>
                        {navigation.map((item) => {
                          if (item !== null) {
                            return (
                              <Link
                                key={item.name}
                                to={item.href}
                                className='block px-3 py-2 text-base font-medium text-gray-900 rounded-md hover:bg-gray-100 hover:text-gray-800'
                              >
                                {item.name}
                              </Link>
                            );
                          }
                        })}
                      </div>
                    </div>
                    <div className='pt-4 pb-2'>
                      {user && user.value && (
                        <div className='flex items-center px-5'>
                          <div className='flex-shrink-0'>
                            <img
                              className='w-10 h-10 rounded-full'
                              src={
                                user.value.profile.avatar || placeholderAvatar
                              }
                              alt=''
                            />
                          </div>
                          <div className='flex-1 min-w-0 ml-3'>
                            <div className='text-base font-medium text-gray-800 truncate'>
                              {user.value.profile.firstName}
                            </div>
                            <div className='text-sm font-medium text-gray-500 truncate'>
                              {user.value.profile.email}
                            </div>
                          </div>
                          <button
                            onClick={() => history.push('/notification')}
                            className='relative flex-shrink-0 p-1 ml-auto text-gray-400 bg-white rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500'
                          >
                            <span className='sr-only'>View notifications</span>
                            <BellIcon className='w-6 h-6' aria-hidden='true' />
                            {notifications && notifications.length ? (
                              <div className='absolute top-0 right-0 w-2 h-2 bg-red-600 rounded-full'></div>
                            ) : null}
                          </button>
                        </div>
                      )}
                      <div className='px-2 mt-3 space-y-1'>
                        {userNavigation.map((item) => (
                          <Link
                            key={item.name}
                            to={item.href}
                            className='block px-3 py-2 text-base font-medium text-gray-900 rounded-md hover:bg-gray-100 hover:text-gray-800'
                          >
                            {item.name}
                          </Link>
                        ))}
                      </div>
                    </div>
                  </div>
                </Popover.Panel>
              </Transition.Child>
            </div>
          </Transition.Root>
        </>
      )}
    </Popover>
  );
};

export default Navbar;
